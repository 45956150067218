import { RootState } from '../../app/store';
import { appApi, isErrorWithMessage, useUploadMutation } from '../../app/api';
import { setIdUploadFile, setImportance, setModel } from '../../app/slice';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import './index.css';
import { Textarea } from 'shared/ui/Textarea';
import { Button } from 'shared/ui/Button';
import { Select } from 'shared/ui/Select';
import { FileInput } from 'shared/ui/FileInput';
import { Spin } from 'shared/ui/Spin';
import { Alert } from 'shared/ui/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { exampleText, models } from './data';
import { Checkbox } from '../../shared/ui/Checkbox';

const Upload = () => {
    const dispatch = useDispatch();
    const [textAlert, setTextAlert] = useState<string>('');
    const { model, idUploadFile, importance } = useSelector((state: RootState) => state.app);
    const [
        upload,
        { isLoading: isLoadingUpload, isSuccess: isSuccessUpload, isError: isErrorUpload, error: errorUpload },
    ] = useUploadMutation({
        fixedCacheKey: 'shared-upload',
    });
    const [checkUpload, { data: dataProcess, isSuccess: isProcessFinish }, lastPromiseInfo] =
        appApi.endpoints.checkUpload.useLazyQuery({
            pollingInterval: isLoadingUpload ? 500 : undefined,
        });

    useEffect(() => {
        if (isLoadingUpload && model?.value) {
            checkUpload({ model: model?.value, id: idUploadFile });
        }
    }, [isLoadingUpload, model, idUploadFile]);

    const fieldDna = useRef<HTMLTextAreaElement>(null);
    const fieldFile = useRef<HTMLInputElement>(null);

    function onChangeModelSelect({ label, value }: { label: string; value: string }) {
        if (value.includes('dnabert')) {
            dispatch(setImportance({ value: false }));
        }
        dispatch(setModel({ label, value }));
        dispatch(setIdUploadFile({ id: Date.now().toString(36) + Math.random().toString(36).slice(2) }));
    }

    const pastExample = () => {
        if (fieldDna?.current) {
            fieldDna.current.value = exampleText;
        }
    };

    const onSubmitForm = async (event: SyntheticEvent) => {
        event.preventDefault();
        try {
           await upload({
                dna: fieldDna?.current?.value ?? '',
                model: model?.value ?? '',
                file: fieldFile?.current?.files && fieldFile.current.files[0],
                id: idUploadFile ?? '',
                importance,
           }).unwrap();
        } catch (err) {
            if (isErrorWithMessage(err)) {
                setTextAlert(err.data.message);
            }
        }
    };

    const textAreaExample = (
        <div className="F-Upload__header-text-area">
            <span className="F-Upload__past-example" onClick={pastExample}>
                paste example
            </span>
        </div>
    );

    return (
        <form className="F-Upload" onSubmit={onSubmitForm}>
            {isErrorUpload && (
                <>
                    <Alert type="error">{textAlert === '' ? 'Error! Try again' : textAlert}</Alert>
                    <br />
                </>
            )}
            {isLoadingUpload ? (
                <>
                    <div className="F-Upload__wrap-spin">
                        <Spin className="F-Upload__spin" progress={dataProcess?.progress ?? 0} />
                    </div>
                    {dataProcess?.progress > 0 ? '' : <p style={{ textAlign: 'center' }}>
                        The job is waiting for server resources to start.<br />Depending on server load, this can take a
                        while.
                    </p>}
                </>
            ) : (
                <>
                    <Textarea helpText='DNA sequence in fasta format. Up to 10 kb if computing importance scores, up to 1 Mb otherwise' ref={fieldDna} name='dna' label="Paste DNA sequence (up to 1 Mb)" example={textAreaExample} rows={10} />
                    <div className='F-Upload__or'>or</div>
                    <FileInput
                        ref={fieldFile}
                        name="file"
                        label="Upload file (fasta format, sequence length up to 1 Mb)"
                        helpText='Up to 10 kb if computing importance scores, up to 1 Mb otherwise'
                    />
                    <div className="F-Upload__hr"></div>
                    <Select
                        name="model"
                        label="Select model"
                        handleOnChange={onChangeModelSelect}
                        options={Object.values(models)}
                        helpText='Each model generates a specific annotation. Read more about models in tutorials and publications.'
                    />
                    <Checkbox
                        name="Compute importance scores"
                        checked={importance}
                        handler={() => {
                            if (!model?.value.includes('dnabert')) {
                                dispatch(setImportance({ value: null }));
                            }
                        }}
                        disabled={!!model?.value.includes('dnabert')}
                        helpText='Importance scores will show how different tokens contribute to the annotated feature. Note that this will significantly extend the computation time,'
                    />
                    <div className="F-Upload__footer">
                        <Button>Annotate</Button>
                    </div>
                </>
            )}
        </form>
    );
};

export { Upload };
