import React, { useState } from 'react';
import './index.css';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

type FileInputProps = {
    name: string;
    label: string;
    helpText?: string;
};

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(({ name, label, helpText}, ref) => {
    const [textFakeInput, setTextFakeInput] = useState('Not loaded');

    const handleChange = () => {
        if (ref && typeof ref !== 'function' && ref.current && ref.current.files) {
            setTextFakeInput(ref.current.files[0].name);
        }
    };

    return (
        <div className="UI-FileInput">
            <label className="UI-FileInput__label">
                <span className="UI-FileInput__field-name">{label}{helpText && <Tooltip text={helpText}/>}</span>
                <span className="UI-FileInput__fake-input">
                    {textFakeInput}
                    <Icon name="upload" />
                </span>
                <input
                    ref={ref}
                    name={name}
                    type="file"
                    className="UI-FileInput__input"
                    hidden={true}
                    onChange={handleChange}
                />
            </label>
        </div>
    );
});

export { FileInput };
