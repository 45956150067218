export const DataGeneral = (
    <p>
        General notes:
        <ol>
            <li>
                Models precision. The language model may not always infer the precise position of a DNA feature, such as
                the splicing site, transcription-binding site, etc. This is because the minimal DNA-sequence unit
                accessible by the model is a token - a small subsequence with a characteristic length of 6-8 base pairs.
                The user should expect the target feature to be somewhere within the token sequence, i.e. reported
                features might be slightly extended compared to true targets.
            </li>
            <li>
                The models are strand-specific. If you want to annotate both + and - strands, submit them separately.
            </li>
            <li>
                We use the igv.js plugin to display features. In this plugin, track names may overlay features, so zoom
                in/out to see all track features and/or use plugin buttons to disable track labels.
            </li>
            <li>
                To open the importance scores track, click on any feature in the IGV screen, which will add a new track.
                This track shows quantitative measurement of how specific input position affects the prediction of the
                selected feature. Higher values indicate regions that are more important for selected feature.
            </li>
        </ol>
    </p>
);

export const DataDescriptions: any = {
    'gena-spliceai': (
        <div>
            <p>
                This page displays two tracks: SA (splice-acceptor) and SD (splice-donor). These tracks correspond to
                splice-acceptor and splice-donor site positions, respectively.
            </p>
        </div>
    ),
    'dnabert-spliceai': (
        <div>
            <p>
                This page displays two tracks: SA (splice-acceptor) and SD (splice-donor). These tracks correspond to
                splice-acceptor and splice-donor site positions, respectively.
            </p>
        </div>
    ),
    'gena-promoters-2000': (
        <div>
            <p>This page displays promoters’ tracks: each 2kb-sequence chunk containing promoter is highlighted</p>
        </div>
    ),
    'dnabert-promoters-2000': (
        <div>
            <p>This page displays promoters’ tracks: each 2kb-sequence chunk containing promoter is highlighted</p>
        </div>
    ),
    'gena-deepsea': (
        <div>
            <p>
                This page displays chromatin profiles, which include ~900 combinations of human cell types (i.e. K562 or
                GM12878) and epigenetic features (i.e. CTCF binding sites or DNAseI hypersensitivity regions). These
                data are grouped into 8 tracks, each containing multiple cell-type-by-feature combinations. For example,
                the first track (DNAseI) contains annotations for DNAseI hypersensitivity regions in all available cell
                types. Zooming in allows seeing a name for each feature, formatted as, for example, K562|DNAse|None.
                This naming should be interpreted as follows: the sequence is expected to contain DNAseI
                hypersensitivity region in cell line K562 cultured w/o any treatments (None).
            </p>
        </div>
    ),
    'dnabert-deepsea': (
        <div>
            <p>
                This page displays chromatin profiles, which include ~900 combinations of human cell types (i.e. K562 or
                GM12878) and epigenetic features (i.e. CTCF binding sites or DNAseI hypersensitivity regions). These
                data are grouped into 8 tracks, each containing multiple cell-type-by-feature combinations. For example,
                the first track (DNAseI) contains annotations for DNAseI hypersensitivity regions in all available cell
                types. Zooming in allows seeing a name for each feature, formatted as, for example, K562|DNAse|None.
                This naming should be interpreted as follows: the sequence is expected to contain DNAseI
                hypersensitivity region in cell line K562 cultured w/o any treatments (None).
            </p>
        </div>
    ),
    'gena-deepstarr': (
        <div>
            <p>
                This page displays enhancer annotations based on Drosophila enhancer properties. Enhancers are shown in
                two tracks: Hk (for housekeeping genes enhancers) and Dev (for developmental genes enhancers). The track
                values should be considered as enhancers’ “strength”; the values are log2-scaled. We recommend
                considering sequences that show values above 2-4 as enhancers.
            </p>
        </div>
    ),
    'dnabert-deepstarr': (
        <div>
            <p>
                This page displays enhancer annotations based on Drosophila enhancer properties. Enhancers are shown in
                two tracks: Hk (for housekeeping genes enhancers) and Dev (for developmental genes enhancers). The track
                values should be considered as enhancers’ “strength”; the values are log2-scaled. We recommend
                considering sequences that show values above 2-4 as enhancers.
            </p>
        </div>
    ),
};
