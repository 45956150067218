import { useRef } from 'react';
import classNames from 'classnames';
import './index.css';
import { Tooltip } from '../Tooltip';

type CheckboxProps = {
    name: string;
    handler?: () => void;
    disabled: boolean;
    checked: boolean | null;
    helpText?: string;
};

const Checkbox = ({ name, handler, checked, disabled = false, helpText}: CheckboxProps) => {
    const ref = useRef<HTMLInputElement>(null);

    return (
        <label className={classNames('UI-Checkbox', {
            'UI-Checkbox--disabled': disabled
        })} onClick={(e) => {
            e.preventDefault()
            if (ref && ref.current) {
                ref.current.checked = !ref.current.checked
            }
            if (handler) {
                handler()
            }
        }}>
            <input ref={ref} className="UI-Checkbox__input" type="checkbox" defaultChecked={!!checked} name={name} disabled={disabled}/>
            <span className='UI-Checkbox__fake-box'></span>
            <span className="UI-Checkbox__wrap-label">
                <span className="UI-Checkbox__label-text">{name}</span>{helpText && <Tooltip text={helpText}/>}</span>
        </label>
    )
}

export { Checkbox };
