import { useEffect, useState } from 'react';
import * as igv from 'igv';
import './index.css';
import { Icon } from '../../shared/ui/Icon';
import { DataDescriptions, DataGeneral } from './data';
import { Button } from '../../shared/ui/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { host, useUploadMutation } from '../../app/api';
import { log } from 'console';

const Genome = () => {
    const { model, importance } = useSelector((state: RootState) => state.app);
    const [_, { isSuccess: isUploadSuccess, data: dataUpload }] = useUploadMutation({
        fixedCacheKey: 'shared-upload',
    });
    const [isShowText, setIsShowText] = useState(false);
    const requestId = dataUpload?.archive.split('/')[3].replace('_archive.zip', '');

    useEffect(() => {
        if (isUploadSuccess && dataUpload) {
            const igvDiv = document.getElementById('igv-div');
            const tracks = [];

            if (dataUpload?.acceptor_bed_file) {
                tracks.push({
                    name: 'acceptor',
                    url: `${host}${dataUpload?.acceptor_bed_file}`,
                });
            }

            if (dataUpload?.bed.length > 0) {
                dataUpload?.bed.forEach((bedFile: any) => {
                    tracks.push({
                        url: `${host}${bedFile}`,
                    });
                });
            }

            const options = {
                reference: {
                    fastaURL: `${host}${dataUpload?.fasta_file}`,
                    indexURL: `${host}${dataUpload?.fai_file}`,
                    tracks: [...tracks],
                },
            };

            igv.createBrowser(igvDiv, options).then((browser: any) => {
                if (importance) {
                    let currentImportanceFeature = '';
                    browser.on('trackclick', (track: any, popoverData: any) => {
                        let symbol = null;
                        
                        if (track.config.format === 'bedgraph') {
                            popoverData.forEach(function(nameValue: any) {
                                if (nameValue.name && nameValue.name.toLowerCase() === 'position:') {
                                    const [start, end] = nameValue.value.split('-');
                                    symbol = `${track._name}_${start}_${end}`;
                                } 
                            });
                        } else {
                            popoverData.forEach(function(nameValue: any) {
                                if (nameValue.name && nameValue.name.toLowerCase() === 'name') {
                                    symbol = nameValue.value;
                                } 
                            });
                        }
                    
                        console.log('symbol: ' + symbol);
                        if (symbol && currentImportanceFeature != symbol) {
                            console.log('Updating importance track');

                            // if there is previously loaded track, remove it
                            if (currentImportanceFeature != '') {
                                console.log('removing track ' + currentImportanceFeature);
                                browser.removeTrackByName(currentImportanceFeature);
                            }

                            currentImportanceFeature = `Importance ${symbol}`;

                            // load feature importance track
                            const trackName = './' + symbol + '.bedGraph';
                            const trackUrl = `${host}/generated/${model?.value}/${requestId}_attributions_${trackName.slice(2)}`;
                            console.log('track_name: ' + trackName);
                            const configTrack = {
                                url: trackUrl,
                                format: 'wig',
                                name: `Importance ${symbol}`,
                            };
                            console.log(browser.loadTrack(configTrack));
                        }

                        // Continue with the default pop-over behavior
                        return popoverData;
                    });
                }
            });
        }
    }, [isUploadSuccess, dataUpload]);

    return (
        <>
            <div className='container'>
                <div className='slider'>
                    <div onClick={() => setIsShowText((prevState) => !prevState)} className='slider__header'>
                        <div className='slider__title'>
                            <Icon name={'question'} />
                            Help
                        </div>
                        {isShowText ? <Icon name={'arrow-top'} /> : <Icon name={'arrow-down'} />}
                    </div>
                    {isShowText && (
                        <div className='slider__content'>
                            <div>{model && DataDescriptions[model.value]}</div>
                            {DataGeneral}
                        </div>
                    )}
                </div>
                <div className='archive'>
                    <Button type={'link'} href={dataUpload && dataUpload?.archive}>
                        Download results
                    </Button>
                </div>
            </div>
            <div className='F-Genome' id='igv-div' />
        </>
    );
};

export { Genome };
