export interface UploadRequest {
    dna: string;
    model: string;
    file?: File | null;
    id: string;
    importance: boolean | null;
}

export interface UploadResponse {
    archive: string;
    bed: string[];
    fai_file: string;
    fasta_file: string;
    faidx_file: string;
    acceptor_bed_file: string;
}

export const exampleText = '>test_sample\n' +
    'GGCCTTCTTTCCATTCCCAGAATATGTTTGCTGCTTTTTCCTCTCCCCAC\n' +
    'TGGCCTAAATGGATCGCTCCGCCTGTTTCCTCCCCAGCACCTAGGGCGCA\n' +
    'ATGGAATATTCCATTGCCCCTCCTGTCCTGGGTCTGTGTTGCGGGGAACG\n' +
    'CTCGCGCGGTTGCCAGAGAAAGCCCCGGACGTGACGGATTTGCGCGACCC\n' +
    'CAAGCAGCCCGCCCTTCCCCCTCCCATCCGTCATTCCCCTGCGCTCTCTT\n' +
    'TCCTCACCCTTCCCCCCGCCACCGTGGGTTCCAGACTTGGGATAAGTAAA\n' +
    'CAGCGGGTGGAGCGAGGCCTACGGACCCAGGCCAGGTGGGAGTCTGCACT\n' +
    'CTTCAAGGGGCCTGGGCTGCTGCTCACGGGTATTAAAGAACTCCGCGTTG\n' +
    'TTCATGGCTGAGGCGATGCATTAGGAAGATCCTGGACCTAGAGAACAAGT\n' +
    'CCCCCGAACGCTGAGTTGGAGGCGGGACTTCGGGTGCGCGTTGGTGCGTC\n' +
    'AACGTGGTGGGGGGGTGTGTTTGTAGGGAGAGGGCTGGAGTAAGTTAAAA\n' +
    'GTAGGCTATTTTGTGACACGGACCTGGTGTGGGAGCGAGAGGAGGTGGCT\n' +
    'TGATTGCCGGGCGTCTGTTCCGAGGGAGGAGGGTGTTGCCATCTCCCTCA\n' +
    'CATGCCCTTATCACCCCTTTCTCAGGCGGGAGCATGCTGGGGCTCTGCGG\n' +
    'GCAGCGGCTCCCCGCGGCGTGGGTCCTGCTTCTGTTGCCTTTCCTGCCGC\n' +
    'TGCTGCTGCTTGCAGCCCCCGCGCCCCACCGCGCGTCCTACAAGCCGGTC\n' +
    'ATCGTGGTGCATGGGCTCTTCGACAGCTCGTACAGCTTCCGCCACCTGCT\n' +
    'GGAATACATCAATGAGGTCTGGCAGGGGACACCTGGGTGCAGGGCGTTAG\n' +
    'AGGCGTCTACTGTGGCAGGGGAGGGAGAGCGGGGAACTGAAAGCCACCCC\n' +
    'TCTGGGCCTGCCCAGTTCCTCAGGGAGCTGGTGCTGGCGTGGGGGAGAGT\n' +
    'TGGGGGACGGGATCCCTGGTTCTAGCAGGGTACAATAGACCTGTGGACGC\n' +
    'GGGCCAGGGGGTGGCGTGTGGGAGCTTCTTAGCCTATCCCCGGTGGCTGC\n' +
    'ATTGCCCCCTTCCCACAGACACACCCCGGGACTGTGGTGACAGTGCTCGA\n' +
    'TCTCTTCGATGGGAGAGAGAGCTTGCGACCCCTGTGGGAACAGGTGCAAG\n' +
    'GGTTCCGAGAGGCTGTGGTCCCCATCATGGCAAAGGCCCCTCAAGGGGTG\n' +
    'CATCTCATCTGCTACTCGCAGGGTAGGCGACTCCCCTGCCCCTAACTCCT\n' +
    'AAGCCCTATCTGAGGCTTGATCCTTATCTGAGGGACACTTCCTAGCGTCC\n' +
    'CTTTTTCTGAACCACATTGCTCCAGGCACAACCCTGGTACCTGAGCCCTT\n' +
    'CCTTTCTGACTTCCCTCAGCACCTGGGTCTCATCTCTGTCTTGAATGGGA\n' +
    'GGGAGGCTCCCTACACTGCTGCCCTTTTGCTTCCTGTTACCCATGGTTCT\n' +
    'TGGACATAAGGGCTAATGGGGCAGGTAAAAACATCCTAGAACTAGAGGCA\n' +
    'GGAGGCCCAGCATCTAATTCGGGCTCAGTCACTTATATGATGTGTGACCT\n' +
    'TTTGGCACAGGGTGTGCCTGCCTTCTGTAAGCCTCAGTCTCCTTTGTGTA\n' +
    'CAGTGTGTGTCTGTGTGTGTCTCTGTGTGTGTGTGTGTGTGTGTGTGTGT\n' +
    'GTGTGTGGTGGGGGTGGGGGGTGCTGCTGGCTTTGCTGTCCTTAAGTGCC\n' +
    'TGCCCAATGTGGTGTTCTGCTTACAGGGGGCCTTGTGTGCCGGGCTCTGC\n' +
    'TTTCTGTCATGGATGATCACAACGTGGATTCTTTCATCTCCCTCTCCTCT\n' +
    'CCACAGATGGGACAGTATGGAGGTGAGTGGGCACTAGACTCCATAGAATG\n' +
    'CCCTGAGTTTTGGGGGAACAGAGGTTTATGGTCACTTAGCATTGCCATTC\n' +
    'GCTTGCCAGACACGGACTACTTGAAGTGGCTGTTCCCCACCTCCATGCGG\n' +
    'TCTAACCTCTATCGGATCTGCTATAGCCCCTGGGGCCAGGAATTCTCCAT\n' +
    'CTGCAACTACTGGCATGGTGAGTGGGGATGCTGAACTGGGGCTTCCATGG\n' +
    'ATCAGGTCAGTTGCTTCCACCTCTGCTACAACCAATAGCAGTGATGACAA\n' +
    'TAAAGATAACTTACATTTATTGAGTTATTTGAACAGGCTCTGTTCAGAAT\n' +
    'TTTTTTTTTTTTTGAGACGGAGTCTTGTTCTGTTGCCCAGGCTGGAGTGC\n' +
    'AGTGCACCATCTTGGCTCACTGCAACCTCCGCCTCCCAGGTTCAAGTGAT\n' +
    'CCTCCTGCCTCAGTCCCCCTAGTAGCTGGGATTACAGGCAGGCGCCATCA\n' +
    'TGCCCGGCTAAGTTTTGTATTTTAAGTAGAGATGGAGTTTCGCCATGTTG\n' +
    'GCCAGGCTGGTCTCGAACTCCTGACCTCAGGTGATCCACTCGCCTCGGCC\n' +
    'TCCCAAAGTGCTGGGATTACAGGTGTGAACCATTGCACCTGGCCCAGAAT\n' +
    'GTTTTAAGTGTGTCACCTTATTGCCTTAGAAGGTTTAGTCTGATGTGGGA\n' +
    'GTCAGCAAACCTTGTCTATAAAGGGCCAGAGAGTAAATATTTTTGACTTT\n' +
    'GTAGGACATATAGTCTGTTTCACAACTCCTCAATTCTGCTGTTGTAGTGT\n' +
    'GAAAGCAGCCATGTACCATATGTGAATGAATGTGCCTGTGTTCCAGTAAA\n' +
    'ACTTCATTTACAAAAACAAGTAGCAGGCTGGATTTGGTCCTTTGGTCACA\n' +
    'GTTTGCCAACCTCTAGACCAGACCATGGGGCCAGAATACTTGGGTTTGAA\n' +
    'TCTTGACCCTATTGGGTGCCTTTGGGCAAGTTACTTAACCATTCTGTTAC\n' +
    'TCAGTTTTCCTTATCTGTAAAATATTATAGCATGTACTTCACCAGGTGGT\n' +
    'TGTAAGGATTAAATAAATAAATGAATGCAATGTACTTTGAATAGTACCTG\n' +
    'GCTCATATAGTAGATACTAGATAGAAGTACTTGCTATTGCCAGGTGTGGT\n' +
    'GGCTCACACCTGTAATCCCAATATCTTGGC\n'


export const models: { [key: string]: any } = {
    'gena-promoters-2000': {
        label: 'Promoters prediction (GENALM)',
        value: 'gena-promoters-2000',
    },
    'dnabert-promoters-2000': {
        label: 'Promoters prediction (DNABERT)',
        value: 'dnabert-promoters-2000',
    },
    'dnabert-promoters-original': {
        label: 'Promoters prediction (DNABERT original)',
        value: 'dnabert-promoters-original',
    },
    'gena-spliceai': {
        label: 'Splice sites annotation (GENALM)',
        value: 'gena-spliceai',
    },
    /*'dnabert-spliceai': {
        label: 'Splice sites annotation (DNABERT)',
        value: 'dnabert-spliceai',
    },*/
    'gena-deepsea': {
        label: 'Mammalian chromatin annotation - 900+ features (GENALM)',
        value: 'gena-deepsea',
    },
    'dnabert-deepsea': {
        label: 'Mammalian chromatin annotation - 900+ features (DNABERT)',
        value: 'dnabert-deepsea',
    },
    'gena-deepstarr': {
        label: 'Drosophila enhancers prediction (GENALM)',
        value: 'gena-deepstarr',
    },
    'dnabert-deepstarr': {
        label: 'Drosophila enhancers prediction (DNABERT)',
        value: 'dnabert-deepstarr',
    },
};
