import { ReactNode } from 'react';
import classNames from 'classnames';
import './index.css';

type Button = {
    type?: 'primary' | 'ghost' | 'link';
    size?: 'default' | 'small';
    href?: string;
    handler?: () => void;
    children: ReactNode;
};

const Button = ({ type = 'primary', size, href, handler, children }: Button) => {
    return (
        <>
            {type === 'link' && href ? (
                <a href={href} className={classNames('UI-Button', 'UI-Button--link')} target="_blank">
                    {children}
                </a>
            ) : (
                <button
                    className={classNames('UI-Button', {
                        'UI-Button--primary': type === 'primary',
                        'UI-Button--link': type === 'link',
                        'UI-Button--ghost': type === 'ghost',
                        'UI-Button--small': size === 'small',
                    })}
                    onClick={handler}
                >
                    {children}
                </button>
            )}
        </>
    );
};

export { Button };
