import React, { ForwardedRef } from 'react';
import './index.css';
import { Tooltip } from '../Tooltip';

type Textarea = {
    name: string;
    label: string | JSX.Element;
    rows?: number;
    helpText?: string | undefined;
    example?: string | JSX.Element;
};

const Textarea = React.forwardRef(
    ({ name, label, rows = 3, helpText, example }: Textarea, ref: ForwardedRef<HTMLTextAreaElement | null>) => (
        <div className="UI-Textarea">
            <label className="UI-Textarea__label">
                <span className="UI-Textarea__field-name">
                    {label}
                    {helpText && <Tooltip text={helpText}/>}
                    {example}
                </span>
                <textarea ref={ref} name={name} className="UI-Textarea__textarea" rows={rows} />
            </label>
        </div>
    )
);

export { Textarea };
