import { useSelector } from 'react-redux';
import { Upload } from 'features/Upload';
import { Genome } from 'features/Genome';
import { RootState } from '../../app/store';

const Home = () => {
    const { statusApp, model } = useSelector((state: RootState) => state.app);
    return (
        <>
            <h1 className="App__title">{statusApp === "FINISH" ? model?.label : 'GENA-Web'}</h1>
            {statusApp === 'FINISH' ? (
                <Genome />
            ) : (
                <Upload />
            )}
        </>
    );
};

export { Home };
