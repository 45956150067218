import classNames from 'classnames';
import './index.css';

type SpinProps = {
    className?: string;
    progress: string;
};

const Spin = ({ className, progress }: SpinProps) => {
    return (
        <div className={classNames('UI-Spin', className)}>
            <div className={classNames('UI-Spin__progress')}>
                <span>{progress}</span>
                <span className={classNames('UI-Spin__progress-%')}>%</span>
            </div>
            <div className={classNames('UI-Spin__loader')} />
        </div>
    );
};

export { Spin };
