import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { UploadRequest, UploadResponse } from 'features/Upload/data';

export let host = '';

if (process.env.NODE_ENV === 'development') {
    host = 'https://dnalm.airi.net';
}

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery({
        baseUrl: host,
    }),
    endpoints: (builder) => ({
        upload: builder.mutation<UploadResponse, UploadRequest>({
            query: ({ dna, model, file, id, importance }: UploadRequest) => {
                const data = new FormData();
                data.append('dna', dna);
                data.append('model', model);
                data.append('id', id)
                data.append('importance', `${importance}`);
                if (file) {
                    data.append('file', file);
                }
                return {
                    url: `/api/${model}/upload`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        checkUpload: builder.query({
            query: ({ model, id }: { model: string; id: string | null }) => {
                if (model.includes('dnabert')) {
                    return {
                        url: `/generated/${model}/DNABERT_storage/request_${id}/progress.json`,
                    };
                }
                return {
                    url: `/generated/${model}/${id}_progress.json`,
                };
            },
        }),
    }),
});

export const { useUploadMutation, useCheckUploadQuery } = appApi;

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    console.log(
        typeof error === 'object' &&
            error != null &&
            'status' in error &&
            'data' in error &&
            typeof error.data === 'object' &&
            error.data != null &&
            !('message' in error.data)
    );
    return (
        typeof error === 'object' &&
        error != null &&
        'status' in error &&
        'data' in error &&
        typeof error.data === 'object' &&
        error.data != null &&
        !('message' in error.data)
    );
}

export function isErrorWithMessage(
    error: unknown
): error is { data: { message: string; status: string }; status: string } {
    console.log(
        typeof error === 'object' &&
            error != null &&
            'data' in error &&
            typeof error.data === 'object' &&
            error.data != null &&
            'message' in error.data &&
            typeof error.data.message === 'string'
    );
    return (
        typeof error === 'object' &&
        error != null &&
        'data' in error &&
        typeof error.data === 'object' &&
        error.data != null &&
        'message' in error.data &&
        typeof error.data.message === 'string'
    );
}
