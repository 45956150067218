import './index.css';

const About = () => (
    <div className="P-About">
        <div className="container">
            <h1 className="App__title">Tutorials</h1>
            <h2>Overview</h2>
            <p>
                GENA-Web is a tool for genomic sequence annotation based on DNA language models. GENA-Web utilizes two
                transformer models trained on human T2T genomic data (DNABERT and BigBird) that produces the embeddings
                for DNA sequence. GENA-Web provides several downstream modules obtained by fine-tuning the models to
                predict specific sequence features: splice-sites, promoters or epigenetic features.
            </p>
            <h2>Usage</h2>
            <ol>
                <li>
                    Provide as DNA sequence as fasta format either by pasting in the text-box below or by uploading
                    file. Multiple sequences can be provided using multi-fasta format. Currently, maximum sequence
                    length is limited to 1 000 000 base pairs and number of simultaneously submitted sequences is
                    limited to 100.
                </li>
                <li>
                    Choose the annotation model from the drop-down box below. All models were obtained by fine-tuning
                    one of the following pre-trained instances:
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://academic.oup.com/bioinformatics/article/37/15/2112/6128680"
                            >
                                DNABERT
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.biorxiv.org/content/10.1101/2023.06.12.544594">
                                GENA-LM
                            </a>
                        </li>
                    </ul>
                    Dataset and fine-tuning parameters are described in{' '}
                    <a target="_blank" href="https://www.biorxiv.org/content/10.1101/2023.06.12.544594">
                        GENA-LM paper
                    </a>
                </li>
                <li>
                    (for GENA models only) Select whether importance scores should be computed. This analysis uses the
                    <a target="_blank" href="https://doi.org/10.48550/arXiv.1703.01365">
                        {' '}
                        Integrated Gradients method
                    </a>
                    , which assigns an "importance" score to each sequence token. A high token importance score suggests
                    that alterations to that token could significantly impact the annotated feature. Note that computing
                    importance scores may substantially increase job run time. We don’t recommend using this option for
                    inputs above 10 kb.
                </li>
                <li>Start analysis by pressing “submit” button</li>
            </ol>
            <h2>Output</h2>
            <p>
                Output After computation completed, annotation data will be available for download as .bigWig files. The
                results can also be reviewed in the integrated IGV browser plugin.
            </p>
            <h2>Time requirements</h2>
            <p>
                The time expected for job computation is ~1min / 20 kb for jobs without importance score computation and
                ~20min/kb when computing importance scores. After submission, the service will display a progress bar
                showing job status.
            </p>
            <h2>Contact</h2>
            <p>
                GENA-Web was developed{' '}
                <a href="http://airi.net/" target="_blank">
                    AIRI
                </a>
            </p>
            <p>
                {' '}
                Contact e-mail: <a href="mailto:bioinformatics@airi.net">bioinformatics@airi.net</a>
            </p>
        </div>
    </div>
);

export { About };
